.sidebarContainer{
    width: 100%;
    margin-top: 30px;
    margin-right: 50px;
}
.sidebarCardHeader {
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    box-sizing: border-box;
    letter-spacing: 2px;
    color: #000;
    font-weight: 600;
}
.sidebarCardTitle{
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    box-sizing: border-box;
    color: #000;
    font-weight: 400;
}
.sidebarCardLink{
    color: #000;
}

.profileImageContainer{
    box-sizing: border-box;
}
.profileImageContainer img{
    max-width: 70%;
    max-height: 70%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block;  
    margin-left: auto;  
    margin-right: auto;  
}

.personalBio{
    font-size: 14px;
    font-weight: 500;
    color: #000;
    text-align: center;
}
/* .recentPosts{
    padding: 20px 0;
}
.recentPost{
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
    text-align: center;
}
.recentPost h3{
    color: #000;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.recentPost span{
    font-size: 10px;
    font-weight: 400;
    color: #000
} */