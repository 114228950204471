.pageNumbers{
    list-style: none;
    display: flex;
    text-align: center;
    justify-content: center;
}

.pageNumbers li{
    background-color: transparent;
    border: 2px solid #f5593d;
    border-radius: 20px;
    color: #f5593d;
    line-height: 1.25rem;
    margin: 0 8px;
    min-width: 36px;
    padding: 4px 6px;
    font-weight: 600;
    cursor: pointer;
}
.pageNumbers li.active{
    background-color: #f5593d;
    border: 2px solid #f5593d;
    border-radius: 20px;
    color: white;
    line-height: 1.25rem;
    margin: 0 8px;
    min-width: 36px;
    padding: 4px 6px;
    font-weight: 600;
}
.pageNumbers li button{
    background-color: transparent;
    border: none;
    color: #f5593d;
    font-size: 1.25rem;
    cursor: pointer;
}
.pageNumbers li button:hover{
    /* background-color: red; */
    color: #000;
}
.pageNumbers li button:focus{
    outline: none;
}
.recentPosts{
    padding: 20px 0;
}
.recentPost{
    /* border-bottom: 1px solid #eee; */
    margin-bottom: 10px;
    text-align: center;
    justify-content: center;
}
.recentPost h3{
    color: #000;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
}
.recentPost span{
    font-size: 10px;
    font-weight: 400;
    color: #000
}
