.postDesc{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left !important;
    visibility: visible;
}
.imgCardResize{
    max-width: 400px !important;
    max-height: 350px !important;
    min-height:350px !important;
    display: block;
    margin-left: auto;
    margin-right: auto;
}